/**
 * @file New version of the button, all instances of the old button to be replaced by this
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DefaultButton = styled.button`
  border-radius: 100px;
  outline: none;
  border: 0;
  padding: 10px 40px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  background-color: ${({ theme, type }) => {
    if (type === 'primary') return theme.Accent
    if (type === 'secondary') return 'unset'
    if (type === 'tertiary') return theme.Tertiary
    return 'unset'
  }};
  color: ${({ theme, type }) => {
    if (type === 'primary') return 'white'
    if (type === 'secondary') return theme.Accent
    if (type === 'tertiary') return `${theme.Text}80`
    return 'unset'
  }};
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ theme, type }) =>
    type === 'secondary' ? theme.Accent : 'transparent'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const SmallButton = styled.button`
  border-radius: 10px;
  outline: none;
  padding: 5px 15px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  background-color: ${({ theme, type }) => {
    if (type === 'primary') return theme.Accent
    if (type === 'secondary') return 'unset'
    if (type === 'tertiary') return theme.Tertiary
    return 'unset'
  }};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, type }) =>
    type === 'secondary' ? theme.Accent : 'transparent'};
  color: ${({ theme, type }) => {
    if (type === 'primary') return 'white'
    if (type === 'secondary') return theme.Accent
    if (type === 'tertiary') return `${theme.Text}80`
    return 'unset'
  }};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const Button = ({ style, size, type, disabled, children, onClick }) => {
  switch (size) {
    case 'default':
      return (
        <DefaultButton
          disabled={disabled}
          type={type}
          onClick={onClick}
          style={style}
        >
          <h3>{children}</h3>
        </DefaultButton>
      )
    case 'small':
      return (
        <SmallButton
          disabled={disabled}
          type={type}
          onClick={onClick}
          style={style}
        >
          <h4>{children}</h4>
        </SmallButton>
      )
    default:
      return null
  }
}

Button.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object]),
  size: PropTypes.oneOf(['default', 'small']),
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  style: {},
  size: 'default',
  type: 'primary',
  disabled: false,
  onClick: () => {},
}

export default Button
